let assetsUrl = 'https://assets.foound.com';
let specificAssetsUrl = 'https://assets.foound.com';
let baseUrl = 'https://api.foound.com';
let jsonUrl = 'https://assets.foound.com/staticV4';
let client = 'general';
let logoUrl = `${assetsUrl}/logo/foound.png`;
let icsFile = 'foound.ics';
let clientUrl = 'https://client.foound.com';
let imgUrl = 'https://assets.foound.com';
let i18nUrl = 'https://assets.foound.com/i18n';

let isIndexUrl = true;

if (
    process.env.NEXT_PUBLIC_CLIENT &&
    process.env.NEXT_PUBLIC_CLIENT === 'elior'
) {
    baseUrl = 'https://api.elior.app';
    logoUrl = `${assetsUrl}/logo/elior.png`;
    icsFile = 'elior.ics';
    clientUrl = 'https://client.elior.app';
    client = 'elior';
} else if (
    process.env.NEXT_PUBLIC_CLIENT &&
    process.env.NEXT_PUBLIC_CLIENT === 'local'
) {
    baseUrl = 'https://api.test';
    logoUrl = `${assetsUrl}/logo/foound.png`;
    icsFile = 'dev.ics';
    clientUrl = 'https://back.menuchic.net';
    client = 'local';
    jsonUrl = 'https://assets.foound.com/dev/staticV4';
    imgUrl = 'https://assets.foound.com/dev';
    specificAssetsUrl = 'https://assets.foound.com/dev';
    isIndexUrl = false;
} else if (
    process.env.NEXT_PUBLIC_CLIENT &&
    process.env.NEXT_PUBLIC_CLIENT === 'eliorpreprod'
) {
    baseUrl = 'https://apielior.foound.com';
    logoUrl = `${assetsUrl}/logo/elior.png`;
    icsFile = 'elior.ics';
    clientUrl = 'https://client.elior.app';
    client = 'eliorpreprod';
    isIndexUrl = false;
} else if (
    process.env.NEXT_PUBLIC_CLIENT &&
    process.env.NEXT_PUBLIC_CLIENT === 'netto'
) {
    baseUrl = 'https://api.foound.it';
    logoUrl = `${assetsUrl}/logo/netto.png`;
    icsFile = 'netto.ics';
    clientUrl = 'https://back-netto.menuchic.com';
    client = 'netto';
    jsonUrl = 'https://assets.foound.com/netto/staticV4';
    imgUrl = 'https://assets.foound.com/netto';
    specificAssetsUrl = 'https://assets.foound.com/netto';
} else if (
    process.env.NEXT_PUBLIC_CLIENT &&
    process.env.NEXT_PUBLIC_CLIENT === 'regioneo'
) {
    assetsUrl = 'https://assets.regioneo.com/regioneo';
    baseUrl = 'https://api.regioneo.com';
    logoUrl = 'https://assets.regioneo.com/logo/regioneo.png';
    icsFile = 'regioneo.ics';
    clientUrl = 'https://back.regioneo.com';
    client = 'regioneo';
    jsonUrl = 'https://assets.foound.com/regioneo/staticV4';
    imgUrl = 'https://assets.foound.com/regioneo';
    specificAssetsUrl = 'https://assets.foound.com/regioneo';
} else if (
    process.env.NEXT_PUBLIC_CLIENT &&
    process.env.NEXT_PUBLIC_CLIENT === 'nettodev'
) {
    baseUrl = 'https://apidev.foound.it';
    logoUrl = `${assetsUrl}/logo/netto.png`;
    icsFile = 'nettot.ics';
    clientUrl = 'https://back-netto-dev.menuchic.com';
    client = 'netto';
    jsonUrl = 'https://assets.foound.com/netto/staticV4';
    imgUrl = 'https://assets.foound.com/netto';
    specificAssetsUrl = 'https://assets.foound.com/netto';
    isIndexUrl = false;
} else if (
    process.env.NEXT_PUBLIC_CLIENT &&
    process.env.NEXT_PUBLIC_CLIENT === 'go4art'
) {
    baseUrl = 'https://api.go-4.art';
    logoUrl = `${assetsUrl}/logo/go4art.png`;
    icsFile = 'go4art.ics';
    clientUrl = 'https://back.go-4.art';
    client = 'go4art';
    jsonUrl = 'https://assets.foound.com/go4art/staticV4';
    imgUrl = 'https://assets.foound.com/go4art';
    specificAssetsUrl = 'https://assets.foound.com/go4art';
    isIndexUrl = false;
} else if (
    process.env.NEXT_PUBLIC_CLIENT &&
    process.env.NEXT_PUBLIC_CLIENT === 'dev'
) {
    baseUrl = 'https://api.menuchic.net';
    logoUrl = `${assetsUrl}/logo/foound.png`;
    icsFile = 'dev.ics';
    clientUrl = 'https://back.menuchic.net';
    client = 'dev';
    jsonUrl = 'https://assets-dev.foound.com/staticV4';
    imgUrl = 'https://assets-dev.foound.com';
    specificAssetsUrl = 'https://assets-dev.foound.com';
    isIndexUrl = false;
}

let apiURL = baseUrl + '/api/v1';
let statsURL = baseUrl;

let lang = 'fr';
let GA = 'UA-126725504-2';

let testcd = process.env.TESTCD ? process.env.TESTCD : 'aaaa';

let originalCountryName = {
    ara: 'العربية',
    cmn: '中国',
    deu: 'Deutsch',
    eng: 'English',
    fra: 'Français',
    hun: 'Magyar',
    ita: 'Italiano',
    jpn: '日本語',
    nld: 'Nederlands',
    por: 'Português',
    spa: 'Español',
    rus: 'Русский',
    vie: 'Tiếng Việt',
};

const firebaseConfig = {
    apiKey: 'AIzaSyDUnyWH7xV3bjwNNCbGmFTGRlWcE-AlhpU',
    projectId: 'foound',
    databaseURL: 'https://foound.firebaseio.com',
    databaseURL: 'https://foound.firebaseio.com',
    storageBucket: 'foound.appspot.com',
};

export default {
    apiUrl: apiURL,
    baseUrl: baseUrl,
    assetsUrl: assetsUrl,
    specificAssetsUrl: specificAssetsUrl,
    imgUrl: imgUrl,
    statsURL: statsURL,
    clientUrl: clientUrl,
    LANG: lang,
    GA: GA,
    client: client,
    originalCountryName: originalCountryName,
    PUSHER_APP_ID: '720543',
    PUSHER_APP_KEY: '798b384a79fbc6c27c1e',
    PUSHER_APP_CLUSTER: 'eu',
    firebaseConfig: firebaseConfig,
    logoUrl: logoUrl,
    icsFile: icsFile,
    testcd: testcd,
    jsonUrl: jsonUrl,
    i18nUrl: i18nUrl,
    isIndexUrl: isIndexUrl,
};
